import { type ErrorResponse } from 'bb/api/browser/types';
import { type Campaign } from '../types';
import { useFetchCampaign } from './useFetchCampaign';
import { usePendingCampaign } from './usePendingCampaign';

export function useFetchPendingCampaign(
    isActivated: boolean,
    campaignCode?: string
): [Campaign, ErrorResponse, boolean] {
    const { data: pendingCampaign } = usePendingCampaign({
        enabled: !isActivated
    });

    const [campaignResponse, campaignError, , campaignResolved] =
        useFetchCampaign(
            campaignCode || pendingCampaign?.campaignCode,
            'signup'
        );
    const resolved = () => {
        if (campaignCode) {
            return campaignResolved;
        }
        if (pendingCampaign) {
            if (pendingCampaign?.campaignCode) {
                return campaignResolved;
            }
            return true;
        }
        return false;
    };

    return [campaignResponse, campaignError, resolved()];
}
