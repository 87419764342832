/**
 * Contains all possible query parameters for the registration.
 * They are used to determine the step of the flow.
 */
export const RegistrationQueryParam = {
    SUBSCRIPTION_TYPE: 'subscriptionType',
    MERCHANT_REFERENCE: 'mref',
    SESSION_ID: 'sessionId',
    REDIRECT_RESULT: 'redirectResult',
    GIFTCARD: 'giftcard',
    CAMPAIGN: 'campaign'
} as const satisfies Record<string, string>;

export const AWAITING_ADYEN_QUERY_REDIRECT_PARAMETERS = [
    RegistrationQueryParam.MERCHANT_REFERENCE,
    RegistrationQueryParam.SESSION_ID,
    RegistrationQueryParam.REDIRECT_RESULT
];

export const ALL_QUERY_PARAMETERS = Object.values(RegistrationQueryParam);
