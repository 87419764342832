import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';
import { observer } from 'mobx-react-lite';
import { SWRConfig, useSWRConfig } from 'swr';
import { useAccountStore, useIsLoggedIn } from 'bb/account';
import { useMutation } from 'bb/api/browser';
import { type UseApiGetReturnType } from 'bb/api/browser/useApi';
import { type UseMutationGetReturnType } from 'bb/api/browser/useMutation';
import { type RegistrationStore } from 'bb/registration';
import { useRegistrationStore } from 'bb/registration/hooks/useRegistrationStore';
import { useSubscriptionStore } from 'bb/subscription/useSubscriptionStore';
import { usePendingCampaign } from './hooks';
import { type Campaign } from './types';
import { isDefaultPendingCampaign } from './utils';

/**
 * Context for campaign related data. We can access each property
 * by using the useCampaign hook. We need to wrap the children in
 * the CampaignProvider in order to use this context.
 */
export type CampaignContextType = {
    updatePendingCampaignMutation: UseMutationGetReturnType<
        'put',
        '/api/my/campaigns/pending'
    >;
    validateSignupMutation: UseMutationGetReturnType<
        'post',
        '/api/campaigns/validate-signup'
    >;
    validateCampaignMutation: UseMutationGetReturnType<
        'post',
        '/api/campaigns/validateCampaign'
    >;
    pendingCampaignGet: UseApiGetReturnType<'/api/my/campaigns/pending'>;
    deletePendingCampaignMutation: UseMutationGetReturnType<
        'delete',
        '/api/my/campaigns/pending'
    >;
    setCampaignFormValues: RegistrationStore['setCampaignFormValues'];
    setHasSubmittedAdditionalData: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    hasSubmittedAdditionalData: boolean;
};

export const CampaignContext = createContext<CampaignContextType>(
    {} as CampaignContextType
);

export const useCampaign = () => useContext(CampaignContext);

export type CampaignProviderProps = React.PropsWithChildren;

const CampaignProviderComponent = observer((props: CampaignProviderProps) => {
    const { children } = props;
    const { cache } = useSWRConfig();

    const accountStore = useAccountStore();
    const registrationStore = useRegistrationStore();
    const subscriptionStore = useSubscriptionStore();

    const isLoggedIn = useIsLoggedIn();

    const [hasSubmittedAdditionalData, setHasSubmittedAdditionalData] =
        useState(false);

    const setCampaignFormValues: typeof registrationStore.setCampaignFormValues =
        useCallback(
            (formValues) => {
                registrationStore.setCampaignFormValues({
                    ...registrationStore.campaignFormValues,
                    ...formValues
                });
            },
            [registrationStore]
        );

    const updatePendingCampaignMutation = useMutation(
        'put',
        '/api/my/campaigns/pending'
    );

    const validateSignupMutation = useMutation(
        'post',
        '/api/campaigns/validate-signup',
        {
            onSuccess: (data) => {
                /**
                 * Keep stores in sync with the fresh data we get.
                 */
                subscriptionStore.setCampaign(data as Campaign);
            }
        }
    );

    const pendingCampaignGet = usePendingCampaign({
        enabled: isLoggedIn && !accountStore.isActivated,
        onFreshData: (data) => {
            if (data.campaignCode) {
                /**
                 * Keep stores in sync with the fresh data we get.
                 */
                setCampaignFormValues({
                    campaignCode: data.campaignCode,
                    isEligibleForConditionalOffer:
                        data.isEligibleForConditionalOffer.toString()
                });
                registrationStore.setCampaignCode(data.campaignCode);

                /**
                 * If the campaignCode equals 'default', we don't need to
                 * do the request below since it is not an actual campaignCode,
                 * but actually only refers to the default campaign which doesn't
                 * have a code.
                 */
                if (
                    !isDefaultPendingCampaign(data) &&
                    /**
                     * Don't re-validate an already validated code.
                     */
                    validateSignupMutation.data?.code !== data.campaignCode
                ) {
                    validateSignupMutation.trigger({
                        code: data.campaignCode
                    });
                }
            }
        }
    });

    const deletePendingCampaignMutation = useMutation(
        'delete',
        '/api/my/campaigns/pending',
        {
            onSuccess: () => {
                /**
                 * Clear all data we have stored that is related to
                 * the campaign.
                 *
                 * We use cache.delete() in order for
                 * the request not to re-run, which would happen if
                 * we would call pendingCampaignGet.mutate().
                 */
                cache.delete('/api/my/campaigns/pending');
                subscriptionStore.clearCampaign();
                registrationStore.clearCampaign();
                validateSignupMutation.reset();
            }
        }
    );

    const validateCampaignMutation = useMutation(
        'post',
        '/api/campaigns/validateCampaign',
        {
            throwOnError: true
        }
    );

    const ctx: CampaignContextType = useMemo(
        () => ({
            updatePendingCampaignMutation,
            validateSignupMutation,
            pendingCampaignGet,
            deletePendingCampaignMutation,
            validateCampaignMutation,
            setCampaignFormValues,
            hasSubmittedAdditionalData,
            setHasSubmittedAdditionalData
        }),
        [
            updatePendingCampaignMutation,
            validateSignupMutation,
            pendingCampaignGet,
            deletePendingCampaignMutation,
            validateCampaignMutation,
            setCampaignFormValues,
            hasSubmittedAdditionalData,
            setHasSubmittedAdditionalData
        ]
    );

    return (
        <CampaignContext.Provider value={ctx}>
            {children}
        </CampaignContext.Provider>
    );
});

export const CampaignProvider = observer((props: CampaignProviderProps) => (
    /**
     * Wrap the CampaignProviderComponent in SWRConfig in order to scope its
     * cache to the children CampaignProvider. For the registration flow we need
     * fresh data for every step, so we don't want to share the cache.
     */
    <SWRConfig>
        <CampaignProviderComponent {...props} />
    </SWRConfig>
));
