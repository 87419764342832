import { useEffect } from 'react';
import { type NextRouter } from 'next/router';

export const useSheerIdQueryParams = (
    callback: (campaign: string, verificationId: string) => void,
    router: NextRouter
): void => {
    useEffect(() => {
        if (!router.isReady) return;
        const { query } = router;
        if (query?.campaign && query?.verificationId && callback) {
            const { verificationId, campaign } = query;
            callback(campaign.toString(), verificationId.toString());
        }
    }, [router, callback]);
};
