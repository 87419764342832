// @ts-strict-ignore
import { useEffect } from 'react';
import { usePost } from 'bb/api/browser';
import { PENDING, RESOLVED } from 'bb/api/browser/symbols';
import { type Status, type ErrorResponse } from 'bb/api/browser/types';
import { useApi } from 'bb/api/browser/useApi';
import { type Campaign } from '../types';

export type IsCampaignCodeValidPayload = {
    code: string;
};

export function useValidateFetchCampaign(
    code: string | undefined,
    validationResult: Campaign,
    validationError: ErrorResponse,
    validationStatus: Status,
    execute
): [Campaign, ErrorResponse, boolean, boolean] {
    const lowerCaseCode = code?.toLowerCase();

    const {
        data: termsResult = null,
        error: termsError = null,
        isLoading: termsIsLoading
    } = useApi('/api/campaigns/terms', {
        query: { code: validationResult?.code },
        enabled: Boolean(
            validationResult?.hasExternalConsents && validationResult?.code
        )
    });

    const isLoading = Boolean(
        validationStatus === PENDING ||
            termsIsLoading ||
            // we have received a campaign but haven't yet started querying for terms
            (validationResult?.hasExternalConsents &&
                !termsResult &&
                !termsError)
    );

    useEffect(() => {
        if (lowerCaseCode && lowerCaseCode !== 'default')
            execute({ code: lowerCaseCode });
    }, [lowerCaseCode, execute]);

    // we cannot spread result {...result} because that would mean that we make
    // a copy of it on every render
    const result = isLoading ? null : validationResult;
    if (result) {
        result.terms = validationResult?.hasExternalConsents ? termsResult : [];
    }

    const error = code ? validationError || termsError : null;

    return [result, error, isLoading, validationStatus === RESOLVED];
}

export function useFetchCampaign(
    code?: string,
    type?: 'signup'
): [Campaign, ErrorResponse, boolean, boolean] {
    const {
        result: validationResult,
        error: validationError,
        status: validationStatus,
        execute
    } = usePost<Campaign, IsCampaignCodeValidPayload>(
        type === 'signup'
            ? '/api/campaigns/validate-signup'
            : '/api/campaigns/validate'
    );
    return useValidateFetchCampaign(
        code,
        validationResult,
        validationError,
        validationStatus,
        execute
    );
}
