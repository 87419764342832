import { useEffect } from 'react';
import { usePost } from 'bb/api/browser';
import { PENDING } from 'bb/api/browser/symbols';
import { type ErrorResponse, type Status } from 'bb/api/browser/types';
import { type ApplyCampaignResponse, type CampaignFormValues } from '../types';

type CampaignPayload = {
    code: string;
    data?: Record<string, unknown>;
};

export function useApplyCampaign(
    campaign: CampaignFormValues | undefined,
    readyToApply: boolean
): [ApplyCampaignResponse, ErrorResponse, Status, boolean] {
    const { result, error, status, execute } = usePost<
        ApplyCampaignResponse,
        CampaignPayload
    >('/api/my/campaigns/apply');

    const isLoading = status === PENDING;

    useEffect(() => {
        if (campaign && readyToApply) {
            const { campaignCode, ...additionalData } = campaign;

            execute({
                code: campaignCode,
                data: {
                    ...additionalData,
                    sheerIdVerificationId: additionalData?.sheeridverification
                }
            });
        }
    }, [execute, campaign, readyToApply]);

    return [result, error, status, isLoading];
}
