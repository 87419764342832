import { type CampaignResponse, type Campaign } from '../types';
import {
    requiresSocialSecurityNumber,
    requiresSheerIdVerification,
    requiresNordicChoiceMembership
} from '../utils';

export const getCampaignRequiresAdditionalData = (
    campaign?: Campaign | CampaignResponse,
    isWaitingForAdditionalData?: boolean
) => {
    const showSocialSecurity = requiresSocialSecurityNumber(campaign);
    const showSheerIdButton =
        requiresSheerIdVerification(campaign) && isWaitingForAdditionalData;
    const showNordicChoice = requiresNordicChoiceMembership(campaign);

    return {
        showSocialSecurity,
        showSheerIdButton,
        showNordicChoice,
        campaignRequiresAdditionalData:
            showSocialSecurity || showNordicChoice || showSheerIdButton
    };
};
