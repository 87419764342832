import React, { type FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { MaxWidthContainer } from 'bb/common/Containers';
import { useHasFeature } from 'bb/config/features';
import { useRegistrationStore } from 'bb/registration';
import { RegistrationQueryParam } from 'bb/registration/components/Registration/consts';
import {
    SubscriptionProductsFooter,
    useSubscriptionStore,
    SubscriptionCards
} from 'bb/subscription';
import { type Product } from 'bb/subscription/types';
import { DeprecatedButton, Flex, Gap, Stack } from 'bb/ui';
import css from './subscriptionProducts.module.scss';

const CampaignCode = dynamic(
    () =>
        import('bb/campaigns/components/CampaignCode').then(
            (m) => m.CampaignCode
        ),
    { ssr: true }
);

const CampaignCodeV2 = dynamic(
    () =>
        import('bb/campaigns/components/CampaignCodeV2').then(
            (m) => m.CampaignCode
        ),
    { ssr: true }
);

export type SubscriptionProductsProps = {
    buttonText: string;
    navigateToSignup?: boolean;
    labelledby?: string;
    onTrackSelected?: (product: string) => void;
    /**
     * Callback that is called when the user submits the selected subscription.
     *
     * If provided the `selectedProduct` and the default `onSubmit` function are
     * passed as arguments. Note that the default `onSubmit` function will have
     * to be called manually if the callback is provided.
     */
    onSelectedSubscriptionSubmit?: (
        selectedProduct: Product,
        defaultOnSubmit: () => Promise<void>
    ) => void | Promise<void>;
    submitDisabled?: boolean;
};

export const SubscriptionProducts: FunctionComponent<SubscriptionProductsProps> =
    observer(
        ({
            buttonText,
            navigateToSignup,
            labelledby,
            onTrackSelected,
            onSelectedSubscriptionSubmit,
            submitDisabled = false
        }) => {
            const subscriptionStore = useSubscriptionStore();
            const registrationStore = useRegistrationStore();
            const { routes, navigate } = useRouter();
            const [selectedProduct, setSelectedProduct] =
                useState<Product | null>(null);
            const [isSubmitting, setIsSubmitting] = useState(false);
            const [showCampaignForm, setShowCampaignForm] = useState(false);

            const hasFeature = useHasFeature();

            const products = subscriptionStore?.campaign?.products?.length
                ? subscriptionStore.campaign.products
                : subscriptionStore?.subscriptionProducts?.products;

            useEffect(() => {
                if (!Array.isArray(products)) return;
                // If the user applies a campaign and one of the campaign products have the same type as the
                // currently selected product, dont proceed to set a default product.
                if (products.find((p) => p.type === selectedProduct?.type)) {
                    return;
                }

                const defaultCampaignProduct = products.find(
                    (p) => p.isDefault === true
                );
                const productByIndex =
                    products.length > 1 ? products[1] : products[0];

                setSelectedProduct(
                    defaultCampaignProduct || productByIndex || null
                );
            }, [products, selectedProduct]);

            const handleSubmitSelectedSubscription = async () => {
                if (!selectedProduct) return;

                const onSubmit = async () => {
                    setIsSubmitting(true);
                    registrationStore.setSubscriptionType(selectedProduct.type);
                    onTrackSelected?.(selectedProduct.type);

                    if (navigateToSignup) {
                        return navigate(routes.userDetails, {
                            [RegistrationQueryParam.SUBSCRIPTION_TYPE]:
                                selectedProduct.displayName
                        });
                    }

                    return undefined;
                };

                if (onSelectedSubscriptionSubmit) {
                    await onSelectedSubscriptionSubmit(
                        selectedProduct,
                        onSubmit
                    );
                } else {
                    await onSubmit();
                }
            };

            const showCampaignCode = !navigateToSignup;

            return (
                <Stack data-optimizely="select-subscription-section">
                    <Gap spacing={7}>
                        {showCampaignCode && (
                            <>
                                {hasFeature('campaign-code-refactor') ? (
                                    <CampaignCodeV2 />
                                ) : (
                                    <CampaignCode
                                        setShowCampaignForm={
                                            setShowCampaignForm
                                        }
                                        showCampaignForm={showCampaignForm}
                                    />
                                )}
                            </>
                        )}
                        <SubscriptionCards
                            products={products || []}
                            selectedProduct={selectedProduct}
                            showProfilePrice
                            setSelectedProduct={setSelectedProduct}
                            labelledby={labelledby}
                            withPagePadding={false}
                        />

                        <Flex
                            className={classNames(
                                css.buttonWrapper,
                                !showCampaignForm && css.stickyButton
                            )}
                        >
                            <DeprecatedButton
                                onClick={handleSubmitSelectedSubscription}
                                variant="primary"
                                disabled={
                                    !selectedProduct ||
                                    showCampaignForm ||
                                    submitDisabled
                                }
                                isLoading={isSubmitting}
                                className={classNames(css.button)}
                                data-testid="confirm-product-selection-button"
                            >
                                {buttonText}
                            </DeprecatedButton>
                        </Flex>
                        <MaxWidthContainer maxWidth={1050}>
                            <SubscriptionProductsFooter
                                subscriptionProducts={
                                    subscriptionStore?.subscriptionProducts
                                }
                            />
                        </MaxWidthContainer>
                    </Gap>
                </Stack>
            );
        }
    );
